import { styled, TableRow } from "@mui/material";

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'isOdd',
})(({ theme, isOdd, selected, erroneous }) => {

  let backgroundColor = !isOdd ? theme.palette.background.default_light : 'inherit';
  let backgroundColorHover = !selected ? theme.palette.hover : theme.palette.primary;

  // if (erroneous === 'true') {
  //   backgroundColor = theme.palette.error.light;
  //   backgroundColorHover = theme.palette.error.main;
  // }

  return {
    cursor: 'pointer',
    backgroundColor: backgroundColor,
    '&.MuiTableRow-hover:hover': {
      backgroundColor: backgroundColorHover,
    }
  }
});

export default StyledTableRow;