import React, { useEffect, useState } from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Snackbar,
  Alert
} from '@mui/material';
import TimezoneSelector from './TimezoneSelector';
import cronstrue from 'cronstrue';

const CronModal = ({ open, handleClose, handleAdd }) => {
  const [name, setName] = useState('');
  const [expression, setExpression] = useState('');
  const [url, setUrl] = useState('');
  const [timezone, setTimezone] = useState('UTC');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [exprDesc, setExprDesc] = useState(null);

  //for testing set default values:
  useEffect(() => {
    setName('Example Cron Job');
    setExpression('* * * * *');
    setUrl('https://www.google.com');
  }, []);

  const handleChangeTimezone = (event) => {
    setTimezone(event.target.value);
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    const newCron = {
      name,
      expression,
      url,
      timezone,
      lastExecution: undefined,
      active: false
    };

    try {
      await handleAdd(newCron);
      handleClose();
    } catch (err) {
      setError(err.message || 'Failed to create cron job. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseError = () => {
    setError('');
  };

  useEffect(() => {
    try {
        setExprDesc(cronstrue.toString(expression ?? ''));
    }catch(error) {
        setExprDesc('Invalid expression');
    }
  }, [expression]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
            Add New Cron Job
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Cron Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Cron Expression"
              variant="outlined"
              value={expression}
              onChange={(e) => setExpression(e.target.value)}
              margin="normal"
              required
              placeholder="* * * * *"
              helperText={exprDesc}
            />
            <TextField
              fullWidth
              label="Cron URL"
              variant="outlined"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              margin="normal"
              required
              type="url"
            />

            <TimezoneSelector value={timezone} onChange={handleChangeTimezone} />

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Add Cron Job
              </Button>
            </Box>
          </form>

        </Box>
      </Modal>

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CronModal;