import { TableCell, TableRow, CircularProgress } from "@mui/material";
import StyledTableRow from "./StyledTableRow";
import moment from "moment";
import { formatAgo } from "../utils/format";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const tableCellStyle = { 
  whiteSpace: 'nowrap', 
  overflow: 'hidden', 
  textOverflow: 'ellipsis', 
  maxWidth: '200px' 
}

const LogRow = ({ index, log, timezonePerspective, selected, onClick }) => {

  var formattedDate = 'No Data';

  if (log?.date) {
    try {
      formattedDate = formatAgo(log.date, timezonePerspective);
    }catch(error) {
      formattedDate = 'Invalid Date';
    }
  }

  const isOdd = index % 2 !== 0;

  return (
    <StyledTableRow 
      selected={selected} 
      onClick={onClick} 
      hover 
      sx={{ 
        height: '50px',
      }} 
      isOdd={isOdd}
      erroneous={log?.status === 'FAILURE' ? 'true' : 'false'}
    >
      <TableCell sx={tableCellStyle}>{log?.cron?.name ?? 'No Data'}</TableCell>
      <TableCell sx={tableCellStyle}>{formattedDate}</TableCell>
      <TableCell sx={tableCellStyle}>{log?.duration ?? '-'}</TableCell>
      <TableCell sx={tableCellStyle}>
        {log?.status === 'PENDING' && (
          <CircularProgress size={20} />
        )}
        {log?.status === 'FAILURE' && (
          <ErrorOutlineIcon color="error" />
        )}
      </TableCell>
    </StyledTableRow>
  );
};

export default LogRow;